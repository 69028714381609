
/********************************************************************************* ADMIN CUSTOM STYLES */

/*  index

    ////// MIXINS & VARIABLES
    ////// COMMONS
    ////// LOGIN, REGISTER, RECOVER PASSWORD PAGE
    ////// POSTER

*/


/*
    ////// MIXINS & VARIABLES
*/
%font-family-main{
    font-family: 'Lato', sans-serif;
}

/*Font Variables*/
$light: 	300;
$regular: 	400;
$bold: 		700;

$color-main:    #5a504c;
$color-secondary: #ff6600;
$color-beige:   #F7F5F2;
$color-red:     #C10A0A;

@mixin font-size($sizeValue) {
    font-size: ($sizeValue * 10) + px;
    font-size: $sizeValue + rem;
    line-height: ($sizeValue * 12.5) + px;
    line-height: ($sizeValue * 1.5) + rem;
}

/*
    ////// COMMONS
*/
html{
    font-size: 62.5%;
}

body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    @extend %font-family-main;
}
.login-page, .register-page{
    background: $color-beige;

    h1{
        @include font-size(2);
        margin: 0 0 10px 0;
    }
    .login-box, .register-box{
        margin: 0 auto;
        padding: 50px 0;
    }
}
.hint{
    @include font-size(1.4);
    font-style: italic;
}

.btn-primary {
    @include button-variant(white, $color-secondary, darken($color-secondary,4%));
}

.wrapper.no-sidebar{
    .content-wrapper, .main-footer{
        margin: 0;
    }
}

.top-menu{
    a, a:link, a:visited, a:active{
        color: $color-secondary!important;
        font-weight: $bold;
    }
}
@media screen and (min-width: 1200px) {
    .create-poster-page{
        .col-xs-12{
            max-width: 1200px;
            margin: 0 auto;
            float: none;
        }
    }
}


/*
    ////// LOGIN, REGISTER, RECOVER PASSWORD PAGE
*/
.error{
    background: $color-red;
    color: white;
    padding: 5px;

}
.forgot-password{
    margin: 5px 0;
    text-align: right;

    a, a:link, a:visited, a:active{
        color: $color-secondary;
        text-decoration: underline;
    }
}

.content-wrapper{
    a, a:link, a:visited, a:active{
        color: $color-secondary;
        text-decoration: underline;
    }
}


/*
    ////// POSTER
*/
button[name="poster-types"]{
    margin-top: -10px;
    margin-bottom: 20px;
}

.promotion-value-type{
    .form-group{
        margin: 0 0 5px 0;
    }
    .hint{
        margin: 0 0 20px 0;
    }
}

#poster-types{
    .modal-body{
        max-height: 80vh;
        overflow: auto;

        h2{
            @include font-size(2);
            font-weight: $bold;
            margin: 0 0 10px 0;
        }
        h3{
            @include font-size(1.6);
            font-weight: $bold;
            margin: 0 0 5px 0;
        }
    }
}

.poster-type-list{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: 0 0 20px 0;
    padding: 0;


    li{
        list-style-type: none;
        margin: 0;
        border: 1px solid $color-secondary;
        text-align: center;

        @media screen and (min-width: 769px) {

            &:hover {
                img {
                    transform: scale(1.5);
                    border: 1px solid $color-secondary;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.6);
                }
            }
        }
    }

    img{
        max-width: 100%;
        transition: all 0.2s ease-in-out;
    }
    p{
        margin: 0;
        border-top: 1px dotted $color-secondary;
        background: $color-main;
        color: white;
        @include font-size(1.2);
        font-weight: $bold;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 400px){
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (min-width: 769px){
    .modal-dialog {
        width: 700px;
        margin: 30px auto;
    }
}

@media screen and (max-width: 768px){
    .col-xs-6{
        width: 100%;
    }
    .skin-black .main-header>.logo{
        background: white;

        &:hover{
            background: white;
        }
    }
}